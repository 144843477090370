.dailychallenges {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.dailychallenges__each {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.dashboard__title {
    margin: 0;
}