.challengeCard {
    width: 30%;
    height: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    text-decoration: none;
    color: black;
}