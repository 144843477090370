.navbar {
    height: 8vh;
    background-color: #2a363b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.navbar__link {
    margin: 10px;
    text-decoration: none;
    color: white;
    display: flex;
}

.navbar__link span {
    margin: auto 0;
}

.navbar__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navbar i {
    color: #3e452e;
}

.navbar__icon {
    color: white;
    text-decoration: none;
}