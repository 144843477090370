h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    padding-bottom: .5rem;
}

main {
    padding: 0 2rem;
}

progress {
    display: block;
    width: 100%;
    height: 2rem;
    border: 0;
    border-radius: 2px;
}

progress::-webkit-progress-bar {
    background-color: var(--blue);
    border-radius: 2px;
}

progress::-webkit-progress-value {
    background: var(--blue);
    border-radius: 2px;
}

progress::-moz-progress-bar {
    background: var(--blue);
    border-radius: 2px;
}