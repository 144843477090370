h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#form-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#form-group {
    display: flex;
    flex-direction: column;
    margin: .5rem;
}

input {
    width: 300px;
    padding: .7rem .6rem;
    border-radius: .1rem;
    font-size: 1.2rem;
    border: 2px solid rgb(1, 42, 61);
    margin: 10px 0;
}

label {
    text-align: left;
}

button {
    margin: 2vh;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-size: 1rem;
    text-decoration: none;
    color: white;
    cursor: pointer;
    border: none;
    background-color: rgb(1, 42, 61);
}

.questionaire {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}