.progressBox {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.dashboard__title {
    /* margin-bottom: 20px; */
}

.chart {
    margin: 20px 0;
}