.leaderboard {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(#ea5455, #ffd460);
}

.leaderboard__one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    height: 220px;
    width: 220px;
    margin-bottom: 20px;
}

.leaderboard__second {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.leaderboard__secondcircle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    height: 170px;
    width: 170px;
    margin-bottom: 20px;
}

.leaderboard__next {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.leaderboard__next>i,
p {
    margin: 10px;
}

.leaderboard__one__trophy {
    color: rgb(255, 238, 0);
}

.leaderboard__two__trophy {
    color: rgb(173, 171, 171);
}

.leaderboard__three__trophy {
    color: rgb(199, 128, 23);
}

.fa-circle {
    color: rgb(230, 134, 134);
}

.leaderboard__nextitem {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    margin: 15px;
    height: 60px;
}

.fa-circle {
    margin-left: 10px;
}