:root {
    --lightblue: #16a9f2;
    --blue: #0085BE;
    --darkblue: #006BAD;
}

.App {
    text-align: center;
    width: 100%;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

* {
    box-sizing: border-box;
}

h4 {
    margin: 20px;
}