.dashboard {
    background-image: linear-gradient(#ea5455, #ffd460);
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
}

.dashboard__tab {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.dashboard__tabLink {
    text-decoration: none;
    color: #2d4059;
}